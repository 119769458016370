.allMemoriesCarouselContainer {
    position: absolute;
    top: 435px;
    width: 100%;
    margin: 0;
}

.img {
    width: 100%;
    height: 100%;
}

.memoriesContainer {
    position: relative;
}

.memoryContainerNoShow {
    /* display: none; */
    display: block;
    position: absolute;
    left: -1500%;
    z-index: -999;
    top: -1500%;
    /* left: -500%;
    position: absolute;
    top: 0; */
}

.memorySelect {
    /* width: 80px !important;
    height: 80px !important;
    margin: 5px 0 !important; */
    width: 80px !important;
    height: 80px !important;
    margin: 5px 0 !important;
    cursor: pointer;
}

.memorySelected {
    width: 80px !important;
    height: 80px !important;
    margin: 5px 0 !important;
    cursor: pointer;
    border: 3px solid red;
}

.secondCarouselContainer {
    /* width: 350px;
    position: absolute;
    top: 500px;
    left: 75px; */
    /* width: 300px;
    position: absolute;
    top: 500px;
    left: 70px; */
    width: 75%;
    position: absolute;
    top: 507px;
    margin: 0 13%
}

@media(min-width: 480px) {
	.secondCarouselContainer {
		width: 75%;
	}
}

*:focus {
    outline: 0;
    outline: none;
}