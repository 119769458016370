.addMemoryText {
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	font-family: 'Roboto', sans-serif;
	width: 300px;
	margin: 35px auto;
}

.collageContainer {
	margin: 0 !important;
	padding: 0 !important;
}

.imageContainer {
	background-color: #C4C4C4;
	height: 105px !important;
	width: 33.33% !important;
	float: left;
	list-style: none;
	position: relative;
}

@media (min-width: 380px) {
	.imageContainer {
		height: 125px !important;
		width: 33.33% !important;
	}
}

@media (min-width: 420px) {
	.imageContainer {
		height: 142px !important;
		width: 33.33% !important;
	}
}

@media (min-width: 480px) {
	.imageContainer {
		height: 160px !important;
		width: 33.33% !important;
	}
}

.info {
	font-size: 12px;
	float: left;
	width: 37px;
	margin: 0 5px;
	text-align: center;
}

@media (min-width: 450px) {
	.info {
		width: 50px;
	}
}

.infoIcon {
	width: 17px !important;
	height: 17px !important;
	position: relative;
	top: 3px;
	margin-right: 5px;
}

.itemCollage {
	cursor: pointer;
	object-fit: cover !important;
	object-position: center !important;
	height: 100% !important;
	width: 100% !important;
	position: relative;
}

.linkMemory {
	padding: 0 !important;
	width: 100% !important;
	height: 100% !important;
}

.moreInfoContainer {
	position: absolute;
	bottom: 5px;
	color: #fff;
	font-family: 'Roboto', sans-serif;
	padding: 0 10px 0 20px;
	width: 100%;
}

.typeFile {
	position: absolute;
	top: 5px;
	color: #fff;
	font-family: 'Roboto', sans-serif;
	right: 5px;
}