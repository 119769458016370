.arrowIcon {
    margin: 0;
    width: 34px;
    height: 34px;
}

.avatarMemory {
    margin: 0;
    width: 100%;
    /* margin: 14px 40px 0 40px;
    width: calc(100% - 80px); */
    height: 100%;
}

.avatarMemoryFull {
    margin: 0;
    width: 100%;
    /* margin: 14px 40px 0 40px;
    width: calc(100% - 80px); */
    height: 100vh;
}

/* .avatarMemory:hover {
    transition: all 300ms;
    transform: scale(1.5);
} */

.avatarMemoryDialog {
    margin: 0;
    width: 100%;
}

.btnCarrousel {
    z-index: 999 !important;
    font-size: 30px !important;
}

.btnCarrousel:hover {
    background-color: transparent !important;
}

.btnCarrouselImage {
    z-index: 999 !important;
    font-size: 30px !important;
    position: absolute !important;
    right: 0;
    bottom: 30px;
}

.btnCarrouselImage:hover {
    background-color: transparent !important;
}

.btnsCarouselContainer {
    position: relative;
    overflow: hidden;
    margin-top: 23px;
    display: flex;
    justify-content: space-between;
}

.closeImageBtn {
    position: absolute !important;
    right: 0 !important;
    top: 10px !important;
    color: #fff !important;
}

.closeModalBtn {
    position: absolute !important;
    right: -5px;
    top: -5px;
    color: #000 !important;
    font-size: 30px !important;
}

.closeModalBtn:hover {
    background: transparent !important;
}

.closeModalIcon {
    font-size: 25px !important;
}

.dateContainer {
    width: 100%;
    overflow: hidden;
}

.fullImageContainer {
    width: 100%;
    height: calc(100vh + 105px);
    background-color: #000;
    position: absolute;
    top: -105px;
    z-index: 99999;
}

.img {
    width: 100%;
    height: 100%;
    /* background: black;
    border-radius: 50%; */
}

.imgMemory {
    /* object-fit: cover !important;
    object-position: center !important;
    height: 100%;
    width: 100%; */
    object-fit: contain !important;
    /* object-position: center !important; */
    height: 100%;
    width: 100%;
    display: block;
}

.imgMemoryDialog {
    object-fit: cover !important;
    object-position: center !important;
    height: 100%;
    width: 100%;
}

.memoryContainerB {
    background-color: #000;
}

.memoryContainerImage {
    max-width: 100%;
    /* width: calc(-260.622px + 80.1913vh); */
    height: 368px;
}

.memoryContainerImageDialog {
    width: 100%;
}

.memoryDate {
    float: right;
    color: #828282;
    font-size: 14px;
    margin-right: 15px;
    font-family: 'Roboto', sans-serif;
}

.memoryNoShow {
    display: none;
}

.memoryOwnerDate {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #FFF;
    position: absolute;
    bottom: 0;
    right: 10px;
}

.memoryOwnerName {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #FFF;
    position: absolute;
    bottom: 0;
    left: 10px;
}

.memorySection {
    position: relative;
}